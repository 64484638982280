import { graphql, Link, withPrefix } from 'gatsby';
import * as React from 'react';

import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { Location, SiteMetadata } from '../models';
import EmailPopup from '../components/email-popup';
import {Helmet} from 'react-helmet'

interface headingLike {
  value: string;
}

interface PageQueryResult {
  site: SiteMetadata;
  markdownRemark: MarkdownRemark;
  allMarkdownRemark: {
    nodes: MarkdownRemark[];
  };
  skillgo_icon: {
    nodes: SkillgoIcon[];
  };
  skillgo_manual_img_en: {
    nodes: SkillgoManualImgEn[];
  }
  skillgo_manual_img_hu: {
    nodes: SkillgoManualImgHu[];
  }
}

interface SkillgoIcon {
  publicURL: string;
}

interface SkillgoManualImgEn {
  publicURL: string;
}

interface SkillgoManualImgHu {
  publicURL: string;
}

interface MarkdownRemark {
  id: string;
  excerpt: string;
  html: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    date: string;
    description: string;
    author: string;
    feed: string;
    coverImage?: {
      publicURL: string;
    };
  };
  headings: headingLike[];
}

interface Props {
  data: PageQueryResult;
}

const TutorialPostTemplate: React.FunctionComponent<Props> = ({ data }: Props) => {
  const post = data.markdownRemark;

  const siteAbsoluteURL = data.site.siteMetadata.siteUrl + data.markdownRemark.fields.slug;
  const imageAbsoluteURL = post.frontmatter.coverImage ? 
      data.site.siteMetadata.siteDomain + post.frontmatter.coverImage.publicURL :
      '';

  let heading_items: string[] = [];

  const activeId = useActiveId(heading_items);


  post.headings.forEach((heading) => {
    const new_id = normalizeText(heading.value);
    heading_items.push(new_id);
    const new_value: string = "<a class=\"tutorial-anchor\" id=\"" + new_id + "\"></a>" +
      "<div class=\"tutorial-header-wrapper\">" +
      "<div class=\"tutorial-header-icon\"><img src=\"" + data.skillgo_icon.nodes[0].publicURL + "\"></div>" +
      "<div><h1>" + heading.value + "</h1></div>" +
      "</div>"
    if (!post.html.includes(new_value)) {
      post.html = post.html.replace("<h1>" + heading.value + "</h1>", new_value)
    }
  })

  function useActiveId(itemIds: string[]) {
    let [activeId, setActiveId] = React.useState(`textformatting`);
    React.useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveId(entry.target.id);
            }
          });
        },
        { rootMargin: `0% 0% -80% 0%` }
      );
      itemIds.forEach((id: string) => {
        const elem = document.getElementById(id);
        if (elem != null) observer.observe(elem);
      });
      return () => {
        itemIds.forEach((id) => {
          const elem = document.getElementById(id);
          if (elem != null) observer.unobserve(elem);
        });
      };
    }, [itemIds]);
    return activeId;
  }


  return (
    <div id="main_content">
      <Helmet>
        <meta property="og:title" content={post.frontmatter.title}></meta>
        <meta property="og:type" content="article" />
        {post.frontmatter.coverImage && (
          <meta property="og:image" content={imageAbsoluteURL}></meta>
        )}
        <meta property="og:url" content={siteAbsoluteURL}></meta>
        <meta property="og:description" content={post.frontmatter.title}></meta>
        <meta property="og:site_name" content="Skillgo"></meta>
        <meta name="twitter:title" content={post.frontmatter.title}></meta>
        <meta name="twitter:description" content={post.frontmatter.title}></meta>
        <meta name="twitter:image" content={imageAbsoluteURL}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <Navbar dark={true} />
      <EmailPopup />

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">{post.frontmatter.title}</h1>
            <ul className="bradcurmed">
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>
              <li>Tutorial</li>
            </ul>

          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <section className="blog-single">
        <div className="container pb-120">
          <div className="row">
            <div className="col-md-8">
              <div className="post-wrapper">
                <article className="post post-signle">
                  {post.frontmatter.coverImage && (
                    <div>
                      <div className="feature-image-tutorial">
                        <a href={withPrefix(`/tutorial/en/`)} target="_blank"
                          style={{ width: '70%' }}>
                          <img style={{ width: '80%' }}
                            src={data.skillgo_manual_img_en.nodes[0].publicURL}
                            alt="English instruction manual"
                            title="English Instruction Manual" />
                        </a>

                        <a href={withPrefix(`/tutorial/hu/`)} target="_blank"
                          style={{ width: '70%' }}>
                          <img style={{ width: '80%' }}
                            src={data.skillgo_manual_img_hu.nodes[0].publicURL}
                            alt="Magyar nyelvű kézikönyv"
                            title="Magyar nyelvű kézikönyv" />
                        </a>
                        <br />
                      </div>
                      <div className="feature-image-tutorial"
                        style={{ marginTop: '10px', marginBottom: '15px' }}>
                        <a href={withPrefix(`/tutorial/en/`)} target="_blank"
                          style={{ marginLeft: '17%' }}>
                          English
                        </a>
                        <a href={withPrefix(`/tutorial/hu/`)} target="_blank"
                          style={{ marginRight: '23%' }}>
                          Magyar
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="blog-content">
                    <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                  </div>
                </article>
                <div className="blog-share mb-5">
                  <div className="share-title">
                    <p>Share:</p>
                  </div>

                  <ul className="share-link">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="sidebar sidebar-tableofcontents">
                <TableOfContents headings={post.headings} activeId={activeId} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TutorialPostTemplate;

export const pageQuery = graphql`
  query TutorialPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        siteDomain
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        feed
        coverImage {
          publicURL
        }
      }
      fields {
        slug
      }
      headings(depth: h1){
        value
      }
    }


    skillgo_icon: allFile(filter: {name: {eq: "skillgo-favicon"}} limit:1){
      nodes {
        publicURL
      }
      
    }

    skillgo_manual_img_en: allFile(filter: {name: {eq: "Skillgo_manual_EN"}} limit:1){
      nodes {
        publicURL
      }
    }

    skillgo_manual_img_hu: allFile(filter: {name: {eq: "Skillgo_manual_HU"}} limit:1){
      nodes {
        publicURL
      }
    }   

    allMarkdownRemark(
      filter: { frontmatter: { feed: { eq: "tutorial" } } }
      
      limit: 3
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
        }
      }
    }
  }
`;

interface TableOfContentsProps {
  headings: headingLike[];
  activeId: string;
}

function TableOfContents({ headings, activeId }: TableOfContentsProps) {
  return (
    <div id="tableofcontents" className="widget widget_tableofcontents">
      <h2 className="widget-title">Table of contents</h2>
      <ul>
        {headings.map((el) => (
          <li key={el.value}>
            <a href={withPrefix(`/tutorial/#` + normalizeText(el.value))}
              className={activeId === normalizeText(el.value) ? "toc-highlight" : ""}
            >{el.value}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

function normalizeText(id: string) {
  const normalized = id
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // clear diactrics
    .replace(/[\s]/g, '-') // replace whitespaces
    .toLowerCase()
    .replace(/[\W]/g, '') // all non word character

  return normalized
}

